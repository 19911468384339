import { api } from "api";
import React, { Component } from 'react';
import classNames from "classnames";
import AsyncSelect from 'react-select/lib/Async';
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { SOLIDO, LATEX, ANIO_INICIAL, meses } from "../../../../utility/constants";
import NumberFormat from 'react-number-format';
import moment from 'moment';

let listaFincas = [], listaAcopios = [], listaTransportistas = [], listaUsuarios = [];
let listaMateriasFiltradas = [], listaProductosFiltrados = [], listaMateriasLatex = [];
let listaAlmacenes = [], listaPerfilesFinca = [], listaBodegas = [], listaPilas = [];
let listaAccionesBitacora = [];

const multiSelecCustomStyles = {
    // Tomado de https://github.com/JedWatson/react-select/issues/1322#issuecomment-436615179
    control: base => ({
        ...base,
        minHeight: 30,
        borderRadius: 25,
    }),
    // Tomado de https://react-select.com/styles#cx-and-custom-components
    multiValue: (styles, { data }) => {
        return {
          ...styles,
        };
      },
};

// PETICIONES HACIA EL BACKEND
const getFincas = (search) => {
    return api.get('finca', { search })
        .then((data) => { listaFincas = data ? data.results : []; return listaFincas })
        .catch(() => []);
};
const getFincasHistorico = search => {
    return api.get('finca_historico', { search })
        .then(data => { listaFincas = data ? data.results : []; return listaFincas })
        .catch(() => []);
}
const getUsuarios = (search) => {
    return api.get('user', { search })
        .then((data) => { listaUsuarios = data ? data.results : []; return listaUsuarios })
        .catch(() => []);
};
const getFincasProveedores = (search, proveedor_id=null) => {
    return api.get('recepcion/get_fincas_proveedores', { search, proveedor_id })
        .then((data) => { listaFincas = data ? data.results : []; return listaFincas })
        .catch(() => []);
};
const getAcopios = (search) => {
    listaAcopios = [{id: 0, nombre: 'Planta ERSA'}];
    return api.get('acopio', { search })
        .then((data) => { data && (listaAcopios = listaAcopios.concat(data.results)); return listaAcopios; })
        .catch(() => listaAcopios);
};
const getTransportistas = (search) => {
    return api.get('transportista', { search })
        .then((data) => { listaTransportistas = data ? data.results : []; return listaTransportistas })
        .catch(() => []);
};
const getMateriasPorTipo = (tipoMateria = null, search = null) => {
    const params = {};
    search !== null && (params.search = search);
    tipoMateria !== null && (params.tipo_materia_prima__tipo = tipoMateria);
    params.no_paginar = true;
    return api.get('materia_prima', {...params})
        .then((data) => { listaMateriasFiltradas = data ? data.results : []; return listaMateriasFiltradas })
        .catch(() => []);
};

const getMateriasSinTipo = (search = null) => {
    const params = {};
    search !== null && (params.search = search);
    params.no_paginar = true;
    return api.get('materia_prima', {...params})
        .then((data) => { listaProductosFiltrados = data ? data.results : []; return listaProductosFiltrados })
        .catch(() => []);
};

const getFincasSearch = (search = null) => {
    const params = {};
    search !== null && (params.search = search);
    params.no_paginar = true;
    return api.get('finca', {...params})
        .then((data) => { listaFincas = data ? data.results : []; return listaFincas })
        .catch(() => []);
};

const getPerfilesFinca = (search) => {
    return api.get('perfil_finca', { search })
        .then((data) => { listaPerfilesFinca = data ? data.results : []; return listaPerfilesFinca })
        .catch(() => []);
};

const getAlmacenesOrigen = (search) => {
    return api.get('bodega/get_almacenes/', { search, no_paginar: true })
        .then((data) => { listaAlmacenes = data ? data.results : []; return listaAlmacenes })
        .catch(() => []);
};
const getAlmacenesDestino = (search) => {
    return api.get('bodega/get_almacenes/', { search, no_paginar: true })
        .then((data) => { listaAlmacenes = data ? data.results : []; return listaAlmacenes })
        .catch(() => []);
};
const getBodegas = (search) => {
    return api.get('bodega', { search })
        .then((data) => { listaBodegas = data ? data.results : []; return listaBodegas })
        .catch(() => []);
};
const getPilas = (search) => {
    return api.get('bodega/get_pilas', { search, no_paginar: true })
        .then((data) => { listaPilas = data ? data.results : []; return listaPilas })
        .catch(() => []);
};
const getMateriasLatex = (search) => {
    const params = {};
    search !== null && (params.search = search);
    params.tipo_materia_prima__tipo = LATEX;
    params.no_paginar = true;
    return api.get('materia_prima', {...params})
        .then((data) => { listaMateriasLatex = data ? data.results : []; return listaMateriasLatex })
        .catch(() => []);
};
const getAccionesBitacora = (search) => {
    const params = {};
    search !== null && (params.search = search);
    return api.get('bitacora/get_acciones', {...params})
        .then((data) => { listaAccionesBitacora = data ? data.results : []; return listaAccionesBitacora })
        .catch(() => []);
};
// BÚSQUEDA DE OBJETOS POR ID EN LISTADOS
const getFincaByID = (idFinca) => {
    let fincaSelec = null;
    listaFincas.forEach(finca => finca.id === idFinca && (fincaSelec = finca));
    return fincaSelec;
};
const getUsuariosByID = (idUsuario) => {
    let usuarioSelec = null;
    listaUsuarios.forEach(user => user.id === idUsuario && (usuarioSelec = user));
    return usuarioSelec;
};
const getAcopioByID = (idAcopio) => {
    let acopioSelec = null;
    listaAcopios.forEach(acopio => acopio.id === idAcopio && (acopioSelec = acopio));
    return acopioSelec;
};
const getTransportistaByID = (idTransportista) => {
    let transportistaSelec = null;
    listaTransportistas.forEach(transportista => transportista.id === idTransportista && (transportistaSelec = transportista));
    return transportistaSelec;
};
const getMateriaFiltradaByID = (idMateria) => {
    let materiaSelec = null;
    listaMateriasFiltradas.forEach(materia => materia.id === idMateria && (materiaSelec = materia));
    return materiaSelec;
};
const getMateriaByIDS = (idsMaterias) => {
    let materiasSelec = [];
    listaMateriasFiltradas.forEach(materia => {
        idsMaterias.forEach(idMateria => materia.id === idMateria && materiasSelec.push(materia));
    });
    return materiasSelec;
};
const getAlmacenOrigenByID = (idAlmacen) => {
    let almacenSelec = null;
    listaAlmacenes.forEach(almacen => almacen.id === idAlmacen && (almacenSelec = almacen));
    return almacenSelec;
};
const getAlmacenDestinoByID = (idAlmacen) => {
    let almacenSelec = null;
    listaAlmacenes.forEach(almacen => almacen.id === idAlmacen && (almacenSelec = almacen));
    return almacenSelec;
};

const getProductosByIDS = (idsProductos) => {
    // Devuelve el listado de fincas, según los IDs proporcionados
    let productosSelec = [];
    listaProductosFiltrados.forEach(producto => {
        idsProductos.forEach(idProducto => producto.id === idProducto && productosSelec.push(producto));
    });
    return productosSelec;
};
const getMateriasLatexByIDS = (idsMaterias) => {
    // Devuelve el listado de fincas, según los IDs proporcionados
    let materiasSelec = [];
    listaMateriasLatex.forEach(materia => {
        idsMaterias.forEach(idMateria => materia.id === idMateria && materiasSelec.push(materia));
    });
    return materiasSelec;
};

const getPerfilesFincaByIDS = (idsPerfiles) => {
    // Devuelve el listado de perfiles de las fincas, según los IDs proporcionados
    let perfilesFincaSelec = [];
    listaPerfilesFinca.forEach(perfil => {
        idsPerfiles.forEach(idPerfil => perfil.id === idPerfil && perfilesFincaSelec.push(perfil));
    });
    return perfilesFincaSelec;
};

const getFincasByIDS = (idsFincas) => {
    // Devuelve el listado de perfiles de las fincas, según los IDs proporcionados
    let fincasSelec = [];
    listaFincas.forEach(perfil => {
        idsFincas.forEach(idPerfil => perfil.id === idPerfil && fincasSelec.push(perfil));
    });
    return fincasSelec;
};
const getBodegasByIDS = (idsBodegas) => {
    let bodegasSelec = [];
    listaBodegas.forEach(bodega => {
        idsBodegas.forEach(idBodega => bodega.id === idBodega && bodegasSelec.push(bodega));
    });
    return bodegasSelec;
};
const getPilasByIDS = (idsPilas) => {
    let pilasSelec = [];
    listaPilas.forEach(pila => {
        idsPilas.forEach(idPila => pila.id === idPila && pilasSelec.push(pila));
    });
    return pilasSelec;
};
const getAccionesBitacoraByAccion = (accionBitacora) => {
    let accionSelec = null;
    listaAccionesBitacora.forEach(accion => accion.accion === accionBitacora && (accionSelec = accion));
    return accionSelec;
};

export default class FiltrosHeader extends Component {
    render() {
        const {
            fechaFinalChange, fechaInicialChange, fecha_inicial, fecha_final,
            filtroEstadoLiquidacionChange, estado_liquidacion,
            filtroFincaChange, finca_id,
            filtroAcopioChange, acopio_id,
            filtroTransportistaChange, transportista_id,
            filtroMateriaChange, materia_id, filtroTipoMateriaChange, tipo_materia,
            filtroMateriasChange, materias_ids,
            filtroProductosChange, productos_ids,
            filtroMateriasLatexChange, materias_latex_ids,
            filtroPerfilChange, perfiles_finca_ids,
            precioReporteAmoniaco, reporte_precio_amoniaco,
            concentracionReporteAmoniaco, reporte_concentracion_amoniaco,
            filtroFincaProveedoresChange, finca_proveedores_id,
            filtroAlmacenOrigenChange, almacen_origen_id, filtroAlmacenDestinoChange, almacen_destino_id,
            filtroMesChange, mes_reporte,
            filtroAnioChange, anio_reporte, botonImprimir, botonExportar,
            filtroSemanaChange, semana_reporte, filtroFincasChange, fincas_ids,
            filtroBodegasChange, bodegas_ids,
            filtroPilasChange, pilas_ids,
            filtroUsuarioChange, usuario_id,
            filtroAccionesBitacoraChange, accion_bitacora,
            filtroFincaHistoricoChange, finca_historico_id,
            aniosChange,
            filtrar_data_laboratorio,
            filtrar_recepciones,
            filtrar,
            filtrar_historico,
            filtrarBodegas,
            loader,
            proveedor,
            filtrarXReporte,
            labelXReporte,
            styleCustomContainer = "",
        } = this.props;
        const TIPO_MATERIA = tipo_materia === 'SOLIDO' ? SOLIDO : LATEX;
        let anios = [];
        let anios_reporte_anual = []
        let anio_inicial = ANIO_INICIAL, anio_actual = moment().year();
        let anio_inicial_reporte_anual = moment().subtract(2, 'years').year()

        for (let i = anio_inicial; i < anio_actual+1; i++) {
            anios.push({value: i, label: i})
        }

        for (let i = anio_inicial_reporte_anual; i < anio_actual + 1; i++) {
            anios_reporte_anual.push({value: i, label: i})
        }

        let semanas = [];
        if (anio_reporte) {
            let semana_inicial = 1;
            let semana_final = 53;
            // console.log(semana_inicial, moment(moment().endOf('year').year(anio_reporte.value)).endOf('week'));
            for (let i = semana_inicial; i < semana_final+1; i++) {
                semanas.push({value: i, label: "Semana "+i})
            }
        }
        // Valores para el filtro de estados de liquidación
        let estados_liquidacion = [{
            value: 'LIQUIDADO', label: 'Liquidado',
        }, {
            value: 'NO_LIQUIDADO', label: 'No liquidado',
        }];

        getMateriasPorTipo(TIPO_MATERIA);
        const tipos_materia = [
            {value: SOLIDO, label: 'SÓLIDO'},
            {value: LATEX,  label: 'LÁTEX'},
        ];
        return (
            <div className={`d-flex flex-wrap justify-content-center align-items-end mb-2 ${styleCustomContainer}`}>
                <div className="d-flex flex-column justify-content-end my-0 px-0 col-12 col-sm-2 col-md-2 col-lg-1 col-xl-1" style={{borderRadius: "1em"}}>
                    <div className="p-0 text-center text-sm-left">
                        <label className="my-0 my-sm-1 mr-2">Filtrar</label>
                    </div>
                </div>
                {(fechaInicialChange !== undefined && fechaInicialChange !== null) && (
                    <div className="d-flex flex-column flex-wrap my-1 my-sm-1 px-0 px-sm-1 col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2" style={{borderRadius: "1em"}}>
                        <div className="p-0">
                            <label className="mb-1 mt-1 mr-2">Fecha inicio</label>
                        </div>
                        <div className="p-0">
                            <DatePicker
                                showPopperArrow={false}
                                dateFormat="DD/MM/YYYY"
                                placeholderText="Fecha de inicio"
                                onChange={(value) => {fechaInicialChange(value)}}
                                selected={fecha_inicial}
                            />
                        </div>
                    </div>
                )}
                {(fechaFinalChange !== undefined && fechaFinalChange !== null) && (
                    <div className="d-flex flex-column flex-wrap my-1 my-sm-1 px-0 px-sm-1 col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2" style={{borderRadius: "1em"}}>
                        <div className="p-0">
                            <label className="mb-1 mt-1 mr-2">Fecha final</label>
                        </div>
                        <div className="p-0">
                            <DatePicker
                                showPopperArrow={false}
                                dateFormat="DD/MM/YYYY"
                                placeholderText="Fecha final"
                                onChange={(value) => {fechaFinalChange(value)}}
                                selected={fecha_final}
                            />
                        </div>
                    </div>
                )}
                {(filtroSemanaChange !== undefined && filtroSemanaChange !== null) && (
                    <div className="d-flex flex-column flex-wrap my-1 my-sm-1 px-0 px-sm-1 col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2" style={{borderRadius: "1em"}}>
                        <div className="p-0">
                            <label className="mb-1 mt-1 mr-2">Semana</label>
                        </div>
                        <div className="p-0">
                            <Select
                                className={classNames('react-select-container')}
                                backspaceRemovesValue={false}
                                isSearchable={true}
                                options={semanas}
                                placeholder="Filtrar semana"
                                onChange={(value) => {filtroSemanaChange(value)}}
                                value={semana_reporte}
                            />
                        </div>
                    </div>
                )}
                {(precioReporteAmoniaco !== undefined && precioReporteAmoniaco !== null) && (
                    <div className="d-flex flex-column flex-wrap my-1 my-sm-1 px-0 px-sm-1 col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2" style={{borderRadius: "1em"}}>
                        <div className="p-0">
                            <label className="mb-1 mt-1 mr-2">Precio de Amoniaco</label>
                        </div>
                        <div className="p-0">
                        <NumberFormat
                            className={classNames('form-control')}
                            decimalScale={2}
                            fixedDecimalScale
                            placeholder="Precio de Amoniaco"
                            value={reporte_precio_amoniaco}
                            thousandSeparator
                            // disabled={disabled}
                            prefix={"Q. "}
                            onValueChange={(values) => {
                                precioReporteAmoniaco(parseFloat(values.value));
                            }}
                        />
                        </div>
                    </div>
                )}
                {(concentracionReporteAmoniaco !== undefined && concentracionReporteAmoniaco !== null) && (
                    <div className="d-flex flex-column flex-wrap my-1 my-sm-1 px-0 px-sm-1 col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2" style={{borderRadius: "1em"}}>
                        <div className="p-0">
                            <label className="mb-1 mt-1 mr-2">Concentración de Amoniaco</label>
                        </div>
                        <div className="p-0">
                        <NumberFormat
                            className={classNames('form-control')}
                            decimalScale={2}
                            fixedDecimalScale
                            placeholder="Concentración de Amoniaco"
                            value={reporte_concentracion_amoniaco}
                            thousandSeparator
                            // disabled={disabled}
                            // prefix={"Q. "}
                            onValueChange={(values) => {
                                concentracionReporteAmoniaco(parseFloat(values.value));
                            }}
                        />
                        </div>
                    </div>
                )}
                {(filtroEstadoLiquidacionChange !== undefined && filtroEstadoLiquidacionChange !== null) && (
                    <div className="d-flex flex-column flex-wrap my-1 my-sm-1 px-0 px-sm-1 col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2" style={{borderRadius: "1em"}}>
                        <div className="p-0">
                            <label className="mb-1 mt-1 mr-2">Estado liquidación</label>
                        </div>
                        <div className="p-0">
                            <Select
                                className={classNames('react-select-container')}
                                backspaceRemovesValue={false}
                                isSearchable={true}
                                isClearable
                                options={estados_liquidacion}
                                placeholder="Sin filtrar"
                                onChange={(value) => {filtroEstadoLiquidacionChange(value)}}
                                value={estado_liquidacion}
                            />
                        </div>
                    </div>
                )}
                {(filtroFincaChange !== undefined && filtroFincaChange !== null) && (
                    <div className="d-flex flex-column flex-wrap my-1 my-sm-1 px-0 px-sm-1 col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2" style={{borderRadius: "1em"}}>
                        <div className="p-0">
                            <label className="mb-1 mt-1 mr-2">Finca</label>
                        </div>
                        <div className="p-0">
                            <AsyncSelect
                                className={classNames('react-select-container')}
                                backspaceRemovesValue={false}
                                isSearchable={true}
                                isClearable
                                placeholder="Todas"
                                cacheOptions
                                defaultOptions
                                loadOptions={(search) => getFincas(search)}
                                onChange={selected => { filtroFincaChange(selected ? selected.id : -1); }}
                                value={getFincaByID(finca_id)}
                                getOptionValue={(option) => (option['id'])}
                                getOptionLabel={(option) => (option['nombre'])}
                            />
                        </div>
                    </div>
                )}
                {(filtroFincaProveedoresChange !== undefined && filtroFincaProveedoresChange !== null) && (
                    <div className="d-flex flex-column flex-wrap my-1 my-sm-1 px-0 px-sm-1 col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2" style={{borderRadius: "1em"}}>
                        <div className="p-0">
                            <label className="mb-1 mt-1 mr-2">Finca</label>
                        </div>
                        <div className="p-0">
                            <AsyncSelect
                                key={proveedor}
                                className={classNames('react-select-container')}
                                backspaceRemovesValue={false}
                                isSearchable={true}
                                isClearable
                                placeholder="Todas"
                                cacheOptions
                                defaultOptions
                                loadOptions={(params) => getFincasProveedores(params, proveedor)}
                                onChange={selected => { filtroFincaProveedoresChange(selected ? selected.id : -1); }}
                                value={getFincaByID(finca_proveedores_id)}
                                getOptionValue={(option) => (option['id'])}
                                getOptionLabel={(option) => (option['nombre'])}
                            />
                        </div>
                    </div>
                )}
                {(filtroFincaHistoricoChange !== undefined && filtroFincaHistoricoChange !== null) && (
                    <div className="d-flex flex-column flex-wrap my-1 my-sm-1 px-0 px-sm-1 col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2" style={{borderRadius: "1em"}}>
                        <div className="p-0">
                            <label className="mb-1 mt-1 mr-2">Finca</label>
                        </div>
                        <div className="p-0">
                            <AsyncSelect
                                className={classNames('react-select-container')}
                                backspaceRemovesValue={false}
                                isSearchable={true}
                                isClearable
                                placeholder="Todas"
                                cacheOptions
                                defaultOptions
                                loadOptions={getFincasHistorico}
                                onChange={selected => {filtroFincaHistoricoChange(selected ? selected.id : -1) }}
                                value={getFincaByID(finca_historico_id)}
                                getOptionValue={option => (option['id'])}
                                getOptionLabel={option => (option['finca'])}
                            />
                        </div>
                    </div>
                )}
                {(filtroAcopioChange !== undefined && filtroAcopioChange !== null) && (
                    <div className="d-flex flex-column flex-wrap my-1 my-sm-1 px-0 px-sm-1 col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2" style={{borderRadius: "1em"}}>
                        <div className="p-0">
                            <label className="mb-1 mt-1 mr-2">Acopio</label>
                        </div>
                        <div className="p-0">
                            <AsyncSelect
                                className={classNames('react-select-container')}
                                backspaceRemovesValue={false}
                                isSearchable={true}
                                isClearable
                                placeholder="Todos"
                                cacheOptions
                                defaultOptions
                                loadOptions={getAcopios}
                                onChange={selected => { filtroAcopioChange(selected ? selected.id : -1); }}
                                value={getAcopioByID(acopio_id)}
                                getOptionValue={(option) => (option['id'])}
                                getOptionLabel={(option) => (option['nombre'])}
                            />
                        </div>
                    </div>
                )}
                {(filtroTransportistaChange !== undefined && filtroTransportistaChange !== null) && (
                    <div className="d-flex flex-column flex-wrap my-1 my-sm-1 px-0 px-sm-1 col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2" style={{borderRadius: "1em"}}>
                        <div className="p-0">
                            <label className="mb-1 mt-1 mr-2">Transportista</label>
                        </div>
                        <div className="p-0">
                            <AsyncSelect
                                className={classNames('react-select-container')}
                                backspaceRemovesValue={false}
                                isSearchable={true}
                                isClearable
                                placeholder="Todos"
                                cacheOptions
                                defaultOptions
                                loadOptions={getTransportistas}
                                onChange={selected => { filtroTransportistaChange(selected ? selected.id : -1); }}
                                value={getTransportistaByID(transportista_id)}
                                getOptionValue={(option) => (option['id'])}
                                getOptionLabel={(option) => (option['nombre_empresa'])}
                            />
                        </div>
                    </div>
                )}
                {(filtroTipoMateriaChange !== undefined && filtroTipoMateriaChange !== null) && (
                    <div className="d-flex flex-column flex-wrap my-1 my-sm-1 px-0 px-sm-1 col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2" style={{borderRadius: "1em"}}>
                        <div className="p-0">
                            <label className="mb-1 mt-1 mr-2">Tipo materia</label>
                        </div>
                        <div className="p-0">
                            <Select
                                className={classNames('react-select-container')}
                                backspaceRemovesValue={false}
                                isClearable
                                isSearchable={false}
                                options={tipos_materia}
                                placeholder="Todos"
                                onChange={(value) => {filtroTipoMateriaChange(value)}}
                                value={tipo_materia}
                            />
                        </div>
                    </div>
                )}
                {(filtroMateriaChange !== undefined && filtroMateriaChange !== null) && (
                    <div className="d-flex flex-column flex-wrap my-1 my-sm-1 px-0 px-sm-1 col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2" style={{borderRadius: "1em"}}>
                        <div className="p-0">
                            <label className="mb-1 mt-1 mr-2">Materia</label>
                        </div>
                        <div className="p-0">
                            <AsyncSelect
                                className={classNames('react-select-container')}
                                backspaceRemovesValue={false}
                                isSearchable={true}
                                isClearable
                                placeholder="Todos"
                                cacheOptions
                                defaultOptions={listaMateriasFiltradas}
                                loadOptions={(search) => getMateriasPorTipo(TIPO_MATERIA, search)}
                                onChange={selected => { filtroMateriaChange(selected ? selected.id : -1); }}
                                value={getMateriaFiltradaByID(materia_id)}
                                getOptionValue={(option) => (option['id'])}
                                getOptionLabel={(option) => (option['nombre'])}
                            />
                        </div>
                    </div>
                )}
                {(filtroMateriasChange !== undefined && filtroMateriasChange !== null) && (
                    <div className="d-flex flex-column flex-wrap my-1 my-sm-1 px-0 px-sm-1 col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2" style={{borderRadius: "1em"}}>
                        <div className="p-0">
                            <label className="mb-1 mt-1 mr-2">Materia</label>
                        </div>
                        <div className="p-0">
                            <AsyncSelect
                                className={classNames('react-select-container')}
                                styles={multiSelecCustomStyles}
                                backspaceRemovesValue={false}
                                isSearchable={true}
                                isClearable
                                placeholder="Todos"
                                cacheOptions
                                defaultOptions={listaMateriasFiltradas}
                                isMulti
                                loadOptions={(search) => getMateriasPorTipo(TIPO_MATERIA,search)}
                                onChange={selected => {
                                    let values = [];
                                    selected && selected.map(selec => { values.push(selec['id']); });
                                    filtroMateriasChange(values);
                                } }
                                value={getMateriaByIDS( materias_ids !==-1 ? materias_ids : []  )}
                                getOptionValue={(option) => (option['id'])}
                                getOptionLabel={(option) => (option['nombre'])}
                            />
                        </div>
                    </div>
                )}
                {aniosChange !== undefined && (
                    <div className="d-flex flex-column flex-wrap my-1 my-sm-1 px-0 px-sm-1 col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2" style={{borderRadius: "1em"}}>
                        <div className="p-0">
                            <label className="mb-1 mt-1 mr-2">Años</label>
                        </div>
                        <div className="p-0">
                            <Select
                                className={classNames('react-select-container')}
                                styles={multiSelecCustomStyles}
                                isMulti
                                options={anios_reporte_anual}
                                onChange={value => aniosChange(value)}
                            />
                        </div>
                    </div>
                )}
                {(filtroAlmacenOrigenChange !== undefined && filtroAlmacenOrigenChange !== null) && (
                    <div className="d-flex flex-column flex-wrap my-1 my-sm-1 px-0 px-sm-1 col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2" style={{borderRadius: "1em"}}>
                        <div className="p-0">
                            <label className="mb-1 mt-1 mr-2">Almacén origen</label>
                        </div>
                        <div className="p-0">
                            <AsyncSelect
                                className={classNames('react-select-container')}
                                backspaceRemovesValue={false}
                                isSearchable={true}
                                isClearable
                                placeholder="Todos"
                                cacheOptions
                                defaultOptions
                                loadOptions={getAlmacenesOrigen}
                                onChange={selected => { filtroAlmacenOrigenChange(selected ? selected.id : -1); }}
                                value={getAlmacenOrigenByID(almacen_origen_id)}
                                getOptionValue={(option) => (option['id'])}
                                getOptionLabel={(option) => (option['nombre'])}
                            />
                        </div>
                    </div>
                )}
                {(filtroAlmacenDestinoChange !== undefined && filtroAlmacenDestinoChange !== null) && (
                    <div className="d-flex flex-column flex-wrap my-1 my-sm-1 px-0 px-sm-1 col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2" style={{borderRadius: "1em"}}>
                        <div className="p-0">
                            <label className="mb-1 mt-1 mr-2">Almacén destino</label>
                        </div>
                        <div className="p-0">
                            <AsyncSelect
                                className={classNames('react-select-container')}
                                backspaceRemovesValue={false}
                                isSearchable={true}
                                isClearable
                                placeholder="Todos"
                                cacheOptions
                                defaultOptions
                                loadOptions={getAlmacenesDestino}
                                onChange={selected => { filtroAlmacenDestinoChange(selected ? selected.id : -1); }}
                                value={getAlmacenDestinoByID(almacen_destino_id)}
                                getOptionValue={(option) => (option['id'])}
                                getOptionLabel={(option) => (option['nombre'])}
                            />
                        </div>
                    </div>
                )}
                {(filtroProductosChange !== undefined && filtroProductosChange !== null) && (
                    <div className="d-flex flex-column flex-wrap my-1 my-sm-1 px-0 px-sm-1 col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2" style={{borderRadius: "1em"}}>
                        <div className="p-0">
                            <label className="mb-1 mt-1 mr-2">Productos</label>
                        </div>
                        <div className="p-0">
                            <AsyncSelect
                                className={classNames('react-select-container')}
                                styles={multiSelecCustomStyles}
                                backspaceRemovesValue={false}
                                isSearchable={true}
                                isClearable
                                placeholder="Todos"
                                cacheOptions
                                defaultOptions
                                isMulti
                                loadOptions={(search) => getMateriasSinTipo(search)}
                                onChange={selected => {
                                    let values = [];
                                    selected && selected.map(selec => { values.push(selec['id']); });
                                    filtroProductosChange(values);
                                } }
                                value={getProductosByIDS(productos_ids)}
                                getOptionValue={(option) => (option['id'])}
                                getOptionLabel={(option) => (option['nombre'])}
                            />
                        </div>
                    </div>
                )}
                {(filtroMateriasLatexChange !== undefined && filtroMateriasLatexChange !== null) && (
                    <div className="d-flex flex-column flex-wrap my-1 my-sm-1 px-0 px-sm-1 col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2" style={{borderRadius: "1em"}}>
                        <div className="p-0">
                            <label className="mb-1 mt-1 mr-2">Materias</label>
                        </div>
                        <div className="p-0">
                            <AsyncSelect
                                className={classNames('react-select-container')}
                                styles={multiSelecCustomStyles}
                                backspaceRemovesValue={false}
                                isSearchable={true}
                                isClearable
                                placeholder="Todos"
                                cacheOptions
                                defaultOptions
                                isMulti
                                loadOptions={(search) => getMateriasLatex(search)}
                                onChange={selected => {
                                    let values = [];
                                    selected && selected.map(selec => { values.push(selec['id']); });
                                    filtroMateriasLatexChange(values);
                                } }
                                value={getMateriasLatexByIDS(materias_latex_ids)}
                                getOptionValue={(option) => (option['id'])}
                                getOptionLabel={(option) => (option['nombre'])}
                            />
                        </div>
                    </div>
                )}
                {(filtroPerfilChange !== undefined && filtroPerfilChange !== null) && (
                    <div className="d-flex flex-column flex-wrap my-1 my-sm-1 px-0 px-sm-1 col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2" style={{borderRadius: "1em"}}>
                        <div className="p-0">
                            <label className="mb-1 mt-1 mr-2">Perfil finca</label>
                        </div>
                        <div className="p-0">
                            <AsyncSelect
                                className={classNames('react-select-container')}
                                styles={multiSelecCustomStyles}
                                backspaceRemovesValue={false}
                                isSearchable={true}
                                isClearable
                                placeholder="Todos"
                                cacheOptions
                                defaultOptions
                                isMulti
                                loadOptions={(search) => getPerfilesFinca(search)}
                                onChange={selected => {
                                    let values = [];
                                    selected && selected.map(selec => { values.push(selec['id']); });
                                    filtroPerfilChange(values);
                                } }
                                value={getPerfilesFincaByIDS(perfiles_finca_ids)}
                                getOptionValue={(option) => (option['id'])}
                                getOptionLabel={(option) => (option['nombre'])}
                            />
                        </div>
                    </div>
                )}
                {(filtroMesChange !== undefined && filtroMesChange !== null) && (
                    <div className="d-flex flex-column flex-wrap my-1 my-sm-1 px-0 px-sm-1 col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2" style={{borderRadius: "1em"}}>
                        <div className="p-0">
                            <label className="mb-1 mt-1 mr-2">Mes</label>
                        </div>
                        <div className="p-0">
                            <Select
                                className={classNames('react-select-container')}
                                backspaceRemovesValue={false}
                                isSearchable={true}
                                options={meses}
                                placeholder="Seleccione"
                                onChange={(value) => {filtroMesChange(value)}}
                                value={mes_reporte}
                                maxMenuHeight={150}
                            />
                        </div>
                    </div>
                )}
                {(filtroAnioChange !== undefined && filtroAnioChange !== null) && (
                    <div className="d-flex flex-column flex-wrap my-1 my-sm-1 px-0 px-sm-1 col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2" style={{borderRadius: "1em"}}>
                        <div className="p-0">
                            <label className="mb-1 mt-1 mr-2">Año</label>
                        </div>
                        <div className="p-0">
                            <Select
                                className={classNames('react-select-container')}
                                backspaceRemovesValue={false}
                                isSearchable={true}
                                options={anios}
                                placeholder="Filtrar año"
                                onChange={(value) => {filtroAnioChange(value)}}
                                value={anio_reporte}
                                maxMenuHeight={150}
                            />
                        </div>
                    </div>
                )}
                {(filtroFincasChange !== undefined && filtroFincasChange !== null) && (
                    <div className="d-flex flex-column flex-wrap my-1 my-sm-1 px-0 px-sm-1 col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2" style={{borderRadius: "1em"}}>
                        <div className="p-0">
                            <label className="mb-1 mt-1 mr-2">Finca</label>
                        </div>
                        <div className="p-0">
                            <AsyncSelect
                                className={classNames('react-select-container')}
                                styles={multiSelecCustomStyles}
                                backspaceRemovesValue={false}
                                isSearchable={true}
                                isClearable
                                placeholder="Todos"
                                cacheOptions
                                defaultOptions
                                isMulti
                                loadOptions={getFincas}
                                onChange={selected => {
                                    let values = [];
                                    selected && selected.map(selec => { values.push(selec['id']); });
                                    filtroFincasChange(values);
                                } }
                                value={getFincasByIDS(fincas_ids)}
                                getOptionValue={(option) => (option['id'])}
                                getOptionLabel={(option) => (option['nombre'])}
                            />
                        </div>
                    </div>
                )}
                {(filtroBodegasChange !== undefined && filtroBodegasChange !== null) && (
                    <div className="d-flex flex-column flex-wrap my-1 my-sm-1 px-0 px-sm-1 col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2" style={{borderRadius: "1em"}}>
                        <div className="p-0">
                            <label className="mb-1 mt-1 mr-2">Pila / Almacén</label>
                        </div>
                        <div className="p-0">
                            <AsyncSelect
                                className={classNames('react-select-container')}
                                styles={multiSelecCustomStyles}
                                backspaceRemovesValue={false}
                                isSearchable={true}
                                isClearable
                                placeholder="Todos"
                                cacheOptions
                                defaultOptions
                                isMulti
                                loadOptions={getBodegas}
                                onChange={selected => {
                                    let values = [];
                                    selected && selected.map(selec => { values.push(selec['id']); });
                                    filtroBodegasChange(values);
                                } }
                                value={getBodegasByIDS(bodegas_ids)}
                                getOptionValue={(option) => (option['id'])}
                                getOptionLabel={(option) => (option['nombre'])}
                            />
                        </div>
                    </div>
                )}
                {(filtroPilasChange !== undefined && filtroPilasChange !== null) && (
                    <div className="d-flex flex-column flex-wrap my-1 my-sm-1 px-0 px-sm-1 col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2" style={{borderRadius: "1em"}}>
                        <div className="p-0">
                            <label className="mb-1 mt-1 mr-2">Pilas</label>
                        </div>
                        <div className="p-0">
                            <AsyncSelect
                                className={classNames('react-select-container')}
                                styles={multiSelecCustomStyles}
                                backspaceRemovesValue={false}
                                isSearchable={true}
                                isClearable
                                placeholder="Todas"
                                cacheOptions
                                defaultOptions
                                isMulti
                                loadOptions={getPilas}
                                onChange={selected => {
                                    let values = [];
                                    selected && selected.map(selec => { values.push(selec['id']); });
                                    filtroPilasChange(values);
                                } }
                                value={getPilasByIDS(pilas_ids)}
                                getOptionValue={(option) => (option['id'])}
                                getOptionLabel={(option) => (option['nombre'])}
                            />
                        </div>
                    </div>
                )}
                {(filtroAccionesBitacoraChange !== undefined && filtroAccionesBitacoraChange !== null) && (
                    <div className="d-flex flex-column flex-wrap my-1 my-sm-1 px-0 px-sm-1 col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2" style={{borderRadius: "1em"}}>
                        <div className="p-0">
                            <label className="mb-1 mt-1 mr-2">Acciones</label>
                        </div>
                        <div className="p-0">
                            <AsyncSelect
                                className={classNames('react-select-container')}
                                backspaceRemovesValue={false}
                                isSearchable={true}
                                isClearable
                                placeholder="Todas"
                                cacheOptions
                                defaultOptions
                                loadOptions={getAccionesBitacora}
                                onChange={selected => { filtroAccionesBitacoraChange(selected ? selected.accion : null); }}
                                value={getAccionesBitacoraByAccion(accion_bitacora)}
                                getOptionValue={(option) => (option['accion'])}
                                getOptionLabel={(option) => (option['accion'])}
                            />
                        </div>
                    </div>
                )}
                {(filtroUsuarioChange !== undefined && filtroUsuarioChange !== null) && (
                    <div className="d-flex flex-column flex-wrap my-1 my-sm-1 px-0 px-sm-1 col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2" style={{borderRadius: "1em"}}>
                        <div className="p-0">
                            <label className="mb-1 mt-1 mr-2">Usuario</label>
                        </div>
                        <div className="p-0">
                            <AsyncSelect
                                className={classNames('react-select-container')}
                                backspaceRemovesValue={false}
                                isSearchable={true}
                                isClearable
                                placeholder="Todos"
                                cacheOptions
                                defaultOptions
                                loadOptions={getUsuarios}
                                onChange={selected => { filtroUsuarioChange(selected ? selected.id : -1); }}
                                value={getUsuariosByID(usuario_id)}
                                getOptionValue={(option) => (option['id'])}
                                getOptionLabel={(option) => (option['nombre'])}
                            />
                        </div>
                    </div>
                )}
                {(filtrarBodegas !== undefined) && (
                    <div className="d-flex my-1 my-sm-1 px-0 px-sm-1 justify-content-center p-0">
                        <button type="submit" className="btn btn-primary" onClick={() => {filtrarBodegas()}} disabled={loader}>FILTRAR BODEGAS</button>
                    </div>
                )}
                {((botonImprimir !== undefined && botonImprimir !== null) || (botonExportar !== undefined && botonExportar !== null)) && (
                    <div className="d-flex flex-row flex-wrap my-1 my-sm-1 px-0 px-sm-1 justify-content-center col-12 col-sm-12 col-md-6 col-lg-4 col-xl-3" style={{borderRadius: "1em"}}>
                        {(botonImprimir !== undefined && botonImprimir !== null) && (
                            <div className="p-0 mr-3">
                                <button type="submit" className="btn btn-secondary" onClick={botonImprimir} disabled={loader}>IMPRIMIR</button>
                            </div>
                        )}
                        {(botonExportar !== undefined && botonExportar !== null) && (
                            <div className="p-0">
                                <button type="submit" className="btn btn-secondary" onClick={botonExportar} disabled={loader}>EXPORTAR</button>
                            </div>
                        )}
                    </div>
                )}
                { (filtroProductosChange !== undefined && filtroProductosChange !== null) && (
                    <div className="d-flex my-1 my-sm-1 px-0 px-sm-1 justify-content-center p-0">
                        <button type="submit" className="btn btn-primary" onClick={()=>{filtrar()}} disabled={loader}>FILTRAR</button>
                    </div>
                ) }
                {(filtrar_data_laboratorio !== undefined) && (
                    <div className="d-flex my-1 my-sm-1 px-0 px-sm-1 justify-content-center p-0">
                        <button type="submit" className="btn btn-primary" onClick={filtrar_data_laboratorio} disabled={loader}>FILTRAR LABORATORIOS</button>
                    </div>
                )}
                {(filtrar_recepciones !== undefined) && (
                    <div className="d-flex my-1 my-sm-1 px-0 px-sm-1 justify-content-center p-0">
                        <button type="submit" className="btn btn-primary" onClick={filtrar_recepciones} disabled={loader}>FILTRAR RECEPCIONES</button>
                    </div>
                )}
                {(filtrar_historico !== undefined) && (
                    <div className="d-flex my-1 my-sm-1 px-0 px-sm-1 justify-content-center p-0">
                        <button type="submit" className="btn btn-primary" onClick={filtrar_historico} disabled={loader}>FILTRAR HISTORICO</button>
                    </div>
                )}
                {(filtrarXReporte !== undefined) && (
                    <div className="d-flex my-1 my-sm-1 px-0 px-sm-1 justify-content-center p-0">
                        <button type="submit" className="btn btn-primary" onClick={filtrarXReporte} disabled={loader}>{labelXReporte}</button>
                    </div>
                )}
            </div>
        );
    }
}
